<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="tagihan"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-8 pt-4 pb-10"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.total_days="{ item }">
          <div>
            <span> {{ item.start_date }} </span>
            <span> - </span>
            <span> {{ item.end_date }} </span>
            <span> {{ ' (' + item.total_days + ' Hari)' }} </span>
          </div>
        </template>

        <template v-slot:item.total_amount="{ item }">
          <div
            style="
            display: flex;
            justify-content: space-between;
          "
          >
            <span>
              Rp
            </span>

            <span>
              {{ item.total_amount | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.promo="{ item }">
          <div>
            <!-- <span>
            {{ item.promo_use.type || '-' }}
          </span> -->
            <span>
              {{ item.promo_use.code || '-' }}
            </span>
            <span>
              {{ '(' + (item.promo_use.amount || 0) + ')' | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition"
          >
            <template v-slot:activator="{ attrs, on }">
              <div
                v-bind="attrs"
                style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                v-on="on"
              >
                <v-icon
                  style="font-size: 16px"
                >
                  fas fa-ellipsis-v
                </v-icon>
              </div>
            </template>

            <div
              class="d-flex px-2 py-2"
              style="background: #F2F2F2"
            >
              <div
                class="py-2 px-2"
                style="
                background: #FAFAFA;
                cursor: pointer;
              "
                @click="toPrint(item)"
              >
                <v-icon style="color: #FFC709">
                  fas fa-print
                </v-icon>
              </div>

              <div
                class="py-2 px-2 ml-2"
                style="
                background: #FAFAFA;
                cursor: pointer;
              "
              >
                <v-icon style="color: #7A1878">
                  fas fa-bell
                </v-icon>
              </div>
            </div>
          </v-menu>
        </template>

        <template v-slot:item.status_string="{ item }">
          <v-chip :color="'#' + item.status_color">
            <span style="color: #FFFFFF">
              {{ item.status_string }}
            </span>
          </v-chip>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              color="purple"
              @input="onChangePage"
            />
            <!-- <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span> -->
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </div>
          <!-- <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row> -->
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      // Tagihan:
      search: '',
      headers: [
        { text: 'Kode Transaksi', align: 'start', value: 'code', sortable: false },
        { text: 'Nama Penyewa', value: 'cust_name' },
        { text: 'Tipe', value: 'cust_type', sortable: false },
        { text: 'Nominal', value: 'total_amount' },
        { text: 'Promo', value: 'promo' },
        { text: 'Tanggal Pemesanan', value: 'created_time' },
        { text: 'Jatuh Tempo', value: 'expiry_time' },
        { text: 'Status', value: 'status_string' },
        { text: '', value: 'actions', sortable: false },
      ],
      tagihan: [],

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      // this.getPerusahaan(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/invoice', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.tagihan = res.data.data.list

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getPerusahaan (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/customer/company', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.perusahaan = res.data.data.list

            vm.pageCountPerusahaan = res.data.data.pagination.page_total
            vm.itemCountPerusahaan = res.data.data.pagination.item_count
            vm.itemTotalPerusahaan = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      showDialogVerifikasiPersonal (item) {
        console.log(item)
        this.selectedMitra = item
        this.dialogVerifikasiPersonal = true
      },

      showDialogVerifikasiPerusahaan (item) {
        console.log(item)
        this.verifikasiPerusahaan = item
        this.dialogVerifikasiBank = true
      },

      verifikasiPersonal (status) {
        if (!this.verifikasiKeterangan.trim().length > 0 && status === 0) {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan').focus()
          }, 100)
          return
        }

        const requestBody = {
          cust_hash: this.$route.params.id,
          submit_status: status,
          no_ktp: this.verifikasiNoNIK,
          reason: this.verifikasiKeterangan,
        }

        axios.post('/v2/t/customer/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiAkunPerusahaan (status) {
        if (!this.verifikasiKeterangan.trim().length > 0 && status === 0) {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan-perusahaan').focus()
          }, 100)
          return
        }

        const requestBody = {
          cust_comp_hash: this.verifikasiPerusahaan.hash,
          submit_status: status,
          no_npwp: this.verifikasiNPWP,
          // doc_arr: xxx
          // keterangan: verifikasiKeteranganPerusahaan
        }

        axios.post('/v1/t/customer/company/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },

      toPrint (item) {
        // console.log(item)
        window.open(item.detail_link, '_blank')
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

#action-container {
  background: #FFFFFF;
}

/* #action-container:hover ~ #action-icon {
  background: #7A1878;
  color: #FFFFFF;
} */

#action-icon {
  color: #7A1878;
}

/* #action-icon:hover {
  color: #FFFFFF;
} */

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
