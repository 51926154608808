var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-sm-10 py-sm-10",staticStyle:{"min-height":"100vh","background":"#E6E6E6"},attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-4"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tagihan,"items-per-page":25,"mobile-breakpoint":"100","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"px-8 pt-4 pb-10",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari","color":"purple","single-line":"","hide-details":"","clearable":""},on:{"click:append":_vm.filterData,"click:clear":_vm.filterClear},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.total_days",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(" "+_vm._s(item.start_date)+" ")]),_c('span',[_vm._v(" - ")]),_c('span',[_vm._v(" "+_vm._s(item.end_date)+" ")]),_c('span',[_vm._v(" "+_vm._s(' (' + item.total_days + ' Hari)')+" ")])])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',[_vm._v(" Rp ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("dotThousandSeparator")(item.total_amount))+" ")])])]}},{key:"item.promo",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(" "+_vm._s(item.promo_use.code || '-')+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("dotThousandSeparator")('(' + (item.promo_use.amount || 0) + ')'))+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"36px","height":"29px","display":"flex","justify-content":"center","align-items":"center","border":"1px solid #F2F2F2","box-sizing":"border-box","border-radius":"4px","cursor":"pointer"}},'div',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"16px"}},[_vm._v(" fas fa-ellipsis-v ")])],1)]}}],null,true)},[_c('div',{staticClass:"d-flex px-2 py-2",staticStyle:{"background":"#F2F2F2"}},[_c('div',{staticClass:"py-2 px-2",staticStyle:{"background":"#FAFAFA","cursor":"pointer"},on:{"click":function($event){return _vm.toPrint(item)}}},[_c('v-icon',{staticStyle:{"color":"#FFC709"}},[_vm._v(" fas fa-print ")])],1),_c('div',{staticClass:"py-2 px-2 ml-2",staticStyle:{"background":"#FAFAFA","cursor":"pointer"}},[_c('v-icon',{staticStyle:{"color":"#7A1878"}},[_vm._v(" fas fa-bell ")])],1)])])]}},{key:"item.status_string",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":'#' + item.status_color}},[_c('span',{staticStyle:{"color":"#FFFFFF"}},[_vm._v(" "+_vm._s(item.status_string)+" ")])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center my-5 py-3"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":5,"color":"purple"},on:{"input":_vm.onChangePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('span',{staticStyle:{"font-size":"12px","font-style":"italic"}},[_vm._v(" "+_vm._s(_vm.itemCount + ' dari ' + _vm.itemTotal + ' ditampilkan')+" ")])],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }